import { vxm } from '@/store/index';

import PessoaTypes, { Fornecedor, Pessoa, PessoaFisica } from '@/core/types/clientes/pessoa/pessoa';
import { Parametros, ParametrosCrm, ParametrosPortal, PermissoesUsuario, ParametrosEmpresa } from '../types/parametros';

import { ParametrosCommerce } from '../types/es/parametrosCommerce';

import FiltrosExtension from '@/utils/filtros';

import CamposTypes, { Campo } from '@/core/types/campos';
import { TipoCliente } from '@/core/enums/pessoa';

import { TipoValidacao } from '@/core/types/campos';
import { Contatos } from '../types/clientes/pessoa/contato';
import { Endereco, EnderecoAdicional, EnderecoCobranca } from '../types/clientes/pessoa/endereco';
import { DadosComerciais, PessoaBanco } from '../types/clientes/pessoa/comercial';

import ValidacoesHandler from '@/core/validations/handler';
import { TipoChavePix } from '../enums/cadastro';

const TAMANHO_CPF = 11;
const TAMANHO_CNPJ = 14;

const { validarCampo, validarEntidade } = ValidacoesHandler;

class PessoaModel {
  //#region VALIDAÇÕES
  private static obterValidacoesPessoa = (
    pessoa: Pessoa,
    parametrosCrm: ParametrosCrm,
    permissoes: PermissoesUsuario,
    pessoaSemAlteracoes: Pessoa | null,
    isNovoCadastro: boolean,
    integradoLinxIO: boolean,
  ): void => {
    if (pessoa.isClienteInternacional.valor) {
      pessoa.documento.validacoes = [];
    } else {
      pessoa.documentoEstrangeiro.validacoes = [];

      const validarDocumento =
        isNovoCadastro || (pessoaSemAlteracoes && pessoaSemAlteracoes.documento.valor !== pessoa.documento.valor);

      if (validarDocumento && !PessoaModel.permiteDocumentoInvalido(pessoa.tipoCliente.valor as number, permissoes))
        pessoa.documento.validacoes.push(
          PessoaModel.isPessoaFisica(pessoa.documento.valor) ? TipoValidacao.cpf : TipoValidacao.cnpj,
        );
    }

    if (parametrosCrm.isObrigatorioClasse) pessoa.classe.validacoes = [TipoValidacao.required];

    if (!pessoa.isClienteInternacional.valor) {
      let obrigaInformarDocumento =
        (pessoa.tipoCliente.valor == TipoCliente.prospect && vxm.parametros.parametrosCrm.isObrigatorioDocProspect) ||
        pessoa.tipoCliente.valor != TipoCliente.prospect;

      const permiteCadastrarSemInformarDocumento =
        vxm.cadastro.dadosIniciaisParaCadastro.tiposRegistrosProfissionais.find(({ id }) => {
          return id === pessoa.idTipoRegistroProfissional.valor;
        })?.permiteCadastrarSemInformarDocumento ?? false;

      if (permiteCadastrarSemInformarDocumento && pessoa.tipoCliente.valor == TipoCliente.fornecedor)
        obrigaInformarDocumento = false;

      if (obrigaInformarDocumento) pessoa.documento.validacoes = [TipoValidacao.required];
      else pessoa.documento.validacoes = [];

      if (pessoa.idTipoRegistroProfissional.valor != null && (pessoa.idTipoRegistroProfissional.valor as string) != '') {
        pessoa.registroProfissional.validacoes = [TipoValidacao.required];
      } else {
        pessoa.registroProfissional.validacoes = [];
      }
    }
    pessoa.nome.validacoes = [TipoValidacao.required];
    pessoa.tipoCliente.validacoes = [TipoValidacao.required];
    if (pessoa.isInscricaoEstadual.valor) pessoa.inscricaoEstadual.validacoes = [TipoValidacao.inscricaoEstadual];
  };
  private static obterValidacoesPessoaFisica = (pessoaFisica: PessoaFisica, parametrosCrm: ParametrosCrm): void => {
    const { dataNascimento, sexo } = pessoaFisica;
    const validacoesdataNascimento = [] as Array<TipoValidacao>;

    if (parametrosCrm.isObrigatorioDataNascimento) validacoesdataNascimento.push(TipoValidacao.required);
    if (parametrosCrm.isObrigatorioSexo) {
      if (sexo.valor !== null && sexo.valor !== '') {
        if (sexo.valor >= 0) sexo.validacoes = [];
      } else sexo.validacoes = [TipoValidacao.required];
    } else sexo.validacoes = [];

    validacoesdataNascimento.push(TipoValidacao.bloquearDataFutura);

    dataNascimento.validacoes = validacoesdataNascimento;
  };
  private static obterValidacoesContatos = (
    contatos: Contatos,
    parametrosCrm: ParametrosCrm,
    isClienteInternacional: boolean,
    pessoa: Pessoa,
  ): void => {
    const validacoesTelefone = [] as Array<TipoValidacao>;
    const validacoesEmail = [] as Array<TipoValidacao>;
    const validacoesCelular = [] as Array<TipoValidacao>;

    if (pessoa.tipoCliente.valor == 5) {
      if (vxm.parametros.parametrosCrm.isObrigatorioTelefoneProspect) {
        validacoesTelefone.push(TipoValidacao.telefone);
        validacoesTelefone.push(TipoValidacao.required);
      }
      if (vxm.parametros.parametrosCrm.isObrigatorioEmailProspect) {
        validacoesEmail.push(TipoValidacao.required);
        validacoesEmail.push(TipoValidacao.email);
      }
    } else {
      if (parametrosCrm.isObrigatorioTelefonePrincipal) validacoesTelefone.push(TipoValidacao.required);
      if (!isClienteInternacional) validacoesTelefone.push(TipoValidacao.telefone);

      if (parametrosCrm.isObrigatorioEmail) validacoesEmail.push(TipoValidacao.required);
      validacoesEmail.push(TipoValidacao.email);

      if (!isClienteInternacional) {
        contatos.telefoneSecundario.validacoes = [TipoValidacao.telefone];
        contatos.celular.validacoes = [TipoValidacao.celular];
      }
    }

    if (!isClienteInternacional) validacoesCelular.push(TipoValidacao.celular);
    if (parametrosCrm.isObrigatorioTelefoneCelular) validacoesCelular.push(TipoValidacao.required);

    contatos.telefonePrincipal.validacoes = validacoesTelefone;
    contatos.email.validacoes = validacoesEmail;
    contatos.celular.validacoes = validacoesCelular;
  };
  private static obterValidacoesEndereco = (
    pessoa: Pessoa,
    parametrosCrm: ParametrosCrm,
    parametrosPortal: ParametrosPortal,
    isClienteInternacional: boolean,
  ): void => {
    if (pessoa.tipoCliente.valor != 5) {
      if (isClienteInternacional) {
        pessoa.endereco.pais.validacoes = [TipoValidacao.required];
        if (parametrosCrm.isObrigatorioCidade) pessoa.endereco.cidade.validacoes = [TipoValidacao.required];
      } else {
        if (parametrosCrm.isObrigatorioCEP) pessoa.endereco.cep.validacoes = [TipoValidacao.required, TipoValidacao.cep];

        if (parametrosCrm.isObrigatorioCEP) {
          if (
            parametrosCrm.isObrigatorioCidade ||
            parametrosPortal.empModNfe ||
            parametrosPortal.empModSpedFiscal ||
            parametrosPortal.empModSpedPisCofins
          )
            pessoa.endereco.cidade.validacoes = [TipoValidacao.required];
          else pessoa.endereco.cidade.validacoes = [];
        } else pessoa.endereco.cidade.validacoes = [];
      }

      if (parametrosCrm.isObrigatorioBairro) pessoa.endereco.bairro.validacoes = [TipoValidacao.required];
      if (parametrosCrm.isObrigatorioLogradouro) pessoa.endereco.logradouro.validacoes = [TipoValidacao.required];
      if (parametrosCrm.isObrigatorioNumero) pessoa.endereco.numero.validacoes = [TipoValidacao.required];
      if (parametrosCrm.isObrigatorioEstado) pessoa.endereco.estado.validacoes = [TipoValidacao.required];
    }
  };
  private static obterValidacoesComercial = (
    comercial: DadosComerciais,
    pessoaObtidaSemAlteracoes: Pessoa | null,
    isNovoCadastro: boolean,
  ): void => {
    if (!comercial.isFaturamentoLiberado.valor) comercial.idMotivoBloqueioFaturamento.validacoes = [TipoValidacao.required];
    if (!isNovoCadastro) {
      if (pessoaObtidaSemAlteracoes) {
        const limiteCrediario = pessoaObtidaSemAlteracoes.comercial.limiteCreditoCrediario.valor;
        if (limiteCrediario === comercial.limiteCreditoCrediario.valor) comercial.limiteCreditoCrediario.validacoes = [];
        else comercial.limiteCreditoCrediario.validacoes = [TipoValidacao.limiteCrediario];
      }
    } else comercial.limiteCreditoCrediario.validacoes = [TipoValidacao.limiteCrediario];
  };
  private static obterValidacoesFornecedor = (fornecedor: Fornecedor, parametrosCrm: ParametrosCrm): void => {
    fornecedor.diferencialIcms.validacoes = [TipoValidacao.diferencialIcms];
  };
  public static validarPessoa = (
    pessoa: Pessoa,
    parametrosCrm: ParametrosCrm,
    parametrosPortal: ParametrosPortal,
    permissoesUsuario: PermissoesUsuario,
    pessoaObtidaSemAtualizacoes: Pessoa | null,
    isNovoCadastro: boolean,
    integradoLinxIO: boolean,
  ): boolean => {
    PessoaModel.obterValidacoesPessoa(
      pessoa,
      parametrosCrm,
      permissoesUsuario,
      pessoaObtidaSemAtualizacoes,
      isNovoCadastro,
      integradoLinxIO,
    );

    PessoaModel.obterValidacoesContatos(pessoa.contatos, parametrosCrm, pessoa.isClienteInternacional.valor, pessoa);
    PessoaModel.obterValidacoesEndereco(pessoa, parametrosCrm, parametrosPortal, pessoa.isClienteInternacional.valor);
    PessoaModel.obterValidacoesComercial(pessoa.comercial, pessoaObtidaSemAtualizacoes, isNovoCadastro);
    PessoaModel.obterValidacoesFornecedor(pessoa.fornecedor, parametrosCrm);
    if (
      PessoaModel.isPessoaFisica(pessoa.documento.valor) &&
      pessoa.documento.valor.length > 0 &&
      pessoa.idTipoRegistroProfissional.valor == null
    )
      PessoaModel.obterValidacoesPessoaFisica(pessoa.pessoaFisica, parametrosCrm);

    const isEntidadeValida = validarEntidade(pessoa);

    return isEntidadeValida;
  };
  public static validarDocumentoComPermissoes = (pessoa: Pessoa, permissoes: PermissoesUsuario): boolean => {
    let obrigaInformarDocumento =
      (pessoa.tipoCliente.valor == TipoCliente.prospect && vxm.parametros.parametrosCrm.isObrigatorioDocProspect) ||
      pessoa.tipoCliente.valor != TipoCliente.prospect ||
      !pessoa.isClienteInternacional;

    const permiteCadastrarSemInformarDocumento =
      vxm.cadastro.dadosIniciaisParaCadastro.tiposRegistrosProfissionais.find(({ id }) => {
        return id === pessoa.idTipoRegistroProfissional.valor;
      })?.permiteCadastrarSemInformarDocumento ?? false;

    if (permiteCadastrarSemInformarDocumento && pessoa.tipoCliente.valor == TipoCliente.fornecedor)
      obrigaInformarDocumento = false;

    if (obrigaInformarDocumento) {
      pessoa.documento.validacoes = [TipoValidacao.required];
    }

    if (!PessoaModel.permiteDocumentoInvalido(pessoa.tipoCliente.valor as number, permissoes))
      pessoa.documento.validacoes.push(
        PessoaModel.isPessoaFisica(pessoa.documento.valor) ? TipoValidacao.cpf : TipoValidacao.cnpj,
      );

    return validarCampo(pessoa.documento);
  };
  public static validarDocumento = (documento: Campo<string>): boolean => {
    documento.validacoes = [
      // TipoValidacao.required,
      PessoaModel.isPessoaFisica(documento.valor) ? TipoValidacao.cpf : TipoValidacao.cnpj,
    ];
    return validarCampo(documento);
  };
  public static validarCep = (cep: Campo<string>): boolean => {
    cep.validacoes = [TipoValidacao.cep];
    return validarCampo(cep);
  };
  public static validarEnderecoAdicional = (enderecoAdicional: EnderecoAdicional): boolean => {
    enderecoAdicional.cep.validacoes = [TipoValidacao.required];
    enderecoAdicional.logradouro.validacoes = [TipoValidacao.required];
    enderecoAdicional.numero.validacoes = [TipoValidacao.required];
    enderecoAdicional.bairro.validacoes = [TipoValidacao.required];
    enderecoAdicional.cidade.validacoes = [TipoValidacao.required];
    enderecoAdicional.estado.validacoes = [TipoValidacao.required];
    enderecoAdicional.documento.validacoes = [TipoValidacao.required];
    enderecoAdicional.telefonePrincipal.validacoes = [TipoValidacao.telefone];
    enderecoAdicional.telefoneSecundario.validacoes = [TipoValidacao.telefone];
    enderecoAdicional.email.validacoes = [TipoValidacao.email];
    enderecoAdicional.inscricaoEstadual.validacoes = [TipoValidacao.inscricaoEstadual];
    if (enderecoAdicional.utilizaEnderecoDocumentoFiscal.valor)
      enderecoAdicional.idClasseFiscal.validacoes = [TipoValidacao.required];

    enderecoAdicional.documento.validacoes.push(
      PessoaModel.isPessoaFisica(enderecoAdicional.documento.valor) ? TipoValidacao.cpf : TipoValidacao.cnpj,
    );

    const isEnderecoAdicionalValido = validarEntidade(enderecoAdicional);

    return isEnderecoAdicionalValido;
  };
  private static validarPessoaBancoDocumento = (pessoaBanco: PessoaBanco): void => {
    if (pessoaBanco.idTipoChavePix.valor !== TipoChavePix.CpfCnpj) return;

    if (FiltrosExtension.permitirApenasNumeros(pessoaBanco.chavePix.valor).length <= 11)
      pessoaBanco.chavePix.validacoes.push(TipoValidacao.cpf);
    else pessoaBanco.chavePix.validacoes.push(TipoValidacao.cnpj);
  };

  private static validarPessoaBancoDemaisPixPadrao = (pessoaBanco: PessoaBanco): void => {
    switch (pessoaBanco.idTipoChavePix.valor) {
      case TipoChavePix.Celular:
        pessoaBanco.chavePix.validacoes.push(TipoValidacao.telefone);
        break;

      case TipoChavePix.ChaveAleatoria:
        pessoaBanco.chavePix.validacoes.push(TipoValidacao.guid);
        break;

      case TipoChavePix.Email:
        pessoaBanco.chavePix.validacoes.push(TipoValidacao.email);
        break;

      default:
        break;
    }
  };

  private static validarPessoaBancoNomeDocumento = (pessoaBanco: PessoaBanco, isContaBancaria: boolean): void => {
    const isContaAgendamentoPagamento = isContaBancaria && pessoaBanco.isContaAgendamentoPagamento.valor;
    const ehCpf = FiltrosExtension.permitirApenasNumeros(pessoaBanco.documentoTitular.valor.toString()).length <= 11;

    if (isContaAgendamentoPagamento) {
      pessoaBanco.nomeTitular.validacoes = [TipoValidacao.required];
      pessoaBanco.documentoTitular.validacoes = [TipoValidacao.required, ehCpf ? TipoValidacao.cpf : TipoValidacao.cnpj];
    } else {
      pessoaBanco.nomeTitular.validacoes = [];
      pessoaBanco.documentoTitular.validacoes = [];
    }
  };

  private static limparValidacoesContaBancaria = (pessoaBanco: PessoaBanco): void => {
    pessoaBanco.idBanco.validacoes = [];
    pessoaBanco.agencia.validacoes = [];
    pessoaBanco.contaCorrente.validacoes = [];
    pessoaBanco.contaCorrenteDigito.validacoes = [];
    pessoaBanco.nomeTitular.validacoes = [];
    pessoaBanco.documentoTitular.validacoes = [];
  };

  public static validarPessoaBanco = (pessoaBanco: PessoaBanco): boolean => {
    const isPix = pessoaBanco.isPix.valor && pessoaBanco.idTipoChavePix.valor !== TipoChavePix.ContaBancaria;
    const isPixPadrao = isPix && pessoaBanco.idTipoChavePix.valor !== TipoChavePix.ContaBancaria;
    const isContaBancaria = !isPix || (isPix && pessoaBanco.idTipoChavePix.valor === TipoChavePix.ContaBancaria);

    if (isPix) pessoaBanco.idTipoChavePix.validacoes = [TipoValidacao.required];
    else pessoaBanco.idTipoChavePix.validacoes = [];

    if (isPixPadrao) {
      pessoaBanco.chavePix.validacoes = [TipoValidacao.required];
      PessoaModel.limparValidacoesContaBancaria(pessoaBanco);
      PessoaModel.validarPessoaBancoDocumento(pessoaBanco);
      PessoaModel.validarPessoaBancoDemaisPixPadrao(pessoaBanco);
    } else {
      pessoaBanco.chavePix.validacoes = [];
    }

    if (isContaBancaria) {
      pessoaBanco.idBanco.validacoes = [TipoValidacao.required];
      pessoaBanco.agencia.validacoes = [TipoValidacao.required];
      pessoaBanco.contaCorrente.validacoes = [TipoValidacao.required];
      pessoaBanco.contaCorrenteDigito.validacoes = [TipoValidacao.required];
    } else {
      PessoaModel.limparValidacoesContaBancaria(pessoaBanco);
    }

    PessoaModel.validarPessoaBancoNomeDocumento(pessoaBanco, isContaBancaria);

    pessoaBanco.fone.validacoes = [TipoValidacao.telefone];

    const isPessoaBancoValido = validarEntidade(pessoaBanco);

    return isPessoaBancoValido;
  };
  //#endregion

  //#region OUTROS
  public static inserirMascaraDocumento = (documento: string): string => {
    if (documento.length === TAMANHO_CPF) return FiltrosExtension.inserirMascaraCpf(documento);
    if (documento.length === TAMANHO_CNPJ) return FiltrosExtension.inserirMascaraCnpj(documento);

    return documento;
  };
  public static isPessoaJuridica = (documento: string): boolean => {
    return documento.length === TAMANHO_CNPJ;
  };
  public static isPessoaFisica = (documento: string): boolean => {
    return documento.length !== TAMANHO_CNPJ;
  };
  public static isPermitirAlterarPessoa = (tipoCliente: number, permissoes: PermissoesUsuario): boolean => {
    switch (tipoCliente) {
      case TipoCliente.ambos:
        return permissoes.isPermitirAlterarCliente || permissoes.isPermitirAlterarFornecedor;
      case TipoCliente.fornecedor:
        return permissoes.isPermitirAlterarFornecedor;
      case TipoCliente.cliente:
        return permissoes.isPermitirAlterarCliente;
      case TipoCliente.transportador:
        return permissoes.isPermitirAlterarTransportador;
      case TipoCliente.prospect:
        return true; //vxm.parametros.parametrosCrm.permiteCadastroProspect;
      default:
        return true;
    }
  };
  public static permiteDocumentoInvalido = (tipoCliente: number, permissoes: PermissoesUsuario): boolean => {
    switch (tipoCliente) {
      case TipoCliente.ambos:
        return permissoes.isPermitirDocumentoInvalidoCliente || permissoes.isPermitirDocumentoInvalidoFornecedor;
      case TipoCliente.fornecedor:
        return permissoes.isPermitirDocumentoInvalidoFornecedor;
      case TipoCliente.cliente:
        return permissoes.isPermitirDocumentoInvalidoCliente;
      case TipoCliente.transportador:
        return permissoes.isPermitirDocumentoInvalidoTransportador;
      case TipoCliente.prospect:
        return vxm.parametros.parametrosCrm.isObrigatorioDocProspect;
      default:
        return true;
    }
  };
  //#endregion
}

export default PessoaModel;
